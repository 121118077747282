import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation JoinQuiz($publicQuizId: String!) {
      joinQuiz(publicQuizId: $publicQuizId) {
        quiz {
          id
          name
        }
      }
    }
  `);
