import React, { useState } from "react";
import Bugsnag from "@bugsnag/js";
import JoinScreen, {
  MAX_PLAYERS_ERROR,
  SIGN_IN,
  UNKNOWN_ERROR,
} from "../../../screens/join/JoinScreen";
import useSignInMutation from "../../../hooks/mutations/useSignInMutation";

const SignInController = ({ onSignIn }) => {
  const [state, setState] = useState(SIGN_IN);
  const [screenName, setScreenName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [signInMutation] = useSignInMutation();

  const handleSignIn = async (screenName) => {
    setSubmitting(true);
    try {
      await signInMutation({
        variables: {
          name: screenName,
        },
      });
      onSignIn();
    } catch (error) {
      Bugsnag.notify(error);
      if (
        error.graphQLErrors[0].message.startsWith(
          "Maximum participants reached"
        )
      ) {
        setState(MAX_PLAYERS_ERROR);
      } else {
        setState(UNKNOWN_ERROR);
      }
      setSubmitting(false);
    }
  };

  const handleTryAgain = () => {
    setScreenName("");
    setState(SIGN_IN);
  };

  return (
    <JoinScreen
      state={state}
      screenName={screenName}
      onScreenNameChange={setScreenName}
      onSignIn={handleSignIn}
      onTryAgain={handleTryAgain}
      submitting={submitting}
    />
  );
};

export default SignInController;
