import React, { useContext } from "react";
import queryString from "query-string";
import PageWrapper from "../components/PageWrapper";
import JoinAndSignIn from "../components/JoinAndSignIn";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import inTesting from "../util/inTesting";
import JoinQuizController from "../v2/controllers/join/JoinQuizController";

export default function JoinQuizPage({ location }) {
  const { flags, flagsReady } = useContext(FeatureFlagContext);
  const { quizId } = queryString.parse(location.search);

  if (!flagsReady && !inTesting()) {
    return null;
  }

  const v2 = flags["v2"];

  return (
    <>
      {v2 && <JoinQuizController publicIdFromUrl={quizId} />}
      {!v2 && (
        <PageWrapper>
          <JoinAndSignIn quizId={quizId} />
        </PageWrapper>
      )}
    </>
  );
}
