import React, { useState } from "react";
import JoinController from "./JoinController";
import SignInController from "./SignInController";
import { navigate } from "gatsby-link";

const JoinQuizController = ({ publicIdFromUrl }) => {
  const [state, setState] = useState("join");

  const handleSignIn = async () => {
    await navigate("/preview-av/?selfie=true");
  };

  return (
    <>
      {state === "join" && (
        <JoinController
          publicIdFromUrl={publicIdFromUrl}
          onJoin={() => setState("sign-in")}
        />
      )}
      {state === "sign-in" && <SignInController onSignIn={handleSignIn} />}
    </>
  );
};

export default JoinQuizController;
