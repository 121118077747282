import React, { useState } from "react";
import Join from "./Join";
import SignIn from "./SignIn";
import { navigate } from "gatsby-link";

export const QUIZ = "quiz";
export const EVENT = "event";

const JOIN = "join";
const SIGN_IN = "signIn";

const JoinAndSignIn = ({ mode = QUIZ, quizId, eventId }) => {
  const [step, setStep] = useState(JOIN);

  return (
    <>
      {step === JOIN && (
        <Join
          mode={mode}
          quizId={quizId}
          eventId={eventId}
          onSuccess={() => setStep(SIGN_IN)}
        />
      )}
      {step === SIGN_IN && (
        <SignIn
          mode={mode}
          onSuccess={() =>
            navigate(
              `/preview-av/${mode === EVENT ? "?next=event&selfie=true" : ""}`
            )
          }
        />
      )}
    </>
  );
};

export default JoinAndSignIn;
