import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Label from "../Label";
import Input from "../Input";
import FormError from "../FormError";
import ButtonRow from "../ButtonRow";
import PrimaryButton from "../buttons/PrimaryButton";
import Dialog from "../Dialog";
import DarkPageHeading from "../DarkPageHeading";
import { EVENT } from "./index";

const JOIN_QUIZ = gql`
  mutation JoinQuiz($publicQuizId: String!) {
    joinQuiz(publicQuizId: $publicQuizId) {
      quiz {
        id
        name
      }
    }
  }
`;

const JOIN_EVENT = gql`
  mutation JoinEvent($publicEventId: String!) {
    joinEvent(publicEventId: $publicEventId) {
      event {
        id
        name
      }
    }
  }
`;

const Join = ({ mode, quizId, eventId, onSuccess }) => {
  const [joinQuiz] = useMutation(JOIN_QUIZ);
  const [joinEvent] = useMutation(JOIN_EVENT);
  const [error, setError] = useState(null);
  const [joining, setJoining] = useState(false);

  const getNoun = (capitalized = false) => {
    let noun;
    if (mode === EVENT) {
      noun = "event";
    } else {
      noun = "quiz";
    }
    if (capitalized) {
      noun = noun.charAt(0).toUpperCase() + noun.slice(1);
    }
    return noun;
  };

  const handleJoin = async (publicId) => {
    if (mode === EVENT) {
      await joinEvent({
        variables: { publicEventId: publicId },
      });
    } else {
      await joinQuiz({
        variables: { publicQuizId: publicId },
      });
    }
    onSuccess();
    setJoining(true);
  };

  useEffect(() => {
    async function doJoin() {
      try {
        if (mode === EVENT) {
          await handleJoin(eventId);
        } else {
          await handleJoin(quizId);
        }
        setJoining(true);
      } catch (error) {
        Bugsnag.notify(error);

        if (error.graphQLErrors[0].message.startsWith(`No such ${getNoun()}`)) {
          setError(`Invalid ${getNoun(true)}`);
        } else {
          setError("Unknown Error");
        }
      }
    }
    if (quizId || eventId) {
      doJoin();
    }
  }, [quizId, eventId]);

  const validate = (values) => {
    const errors = {};
    if (!values.meetingId) {
      errors.meetingId = `You must enter the ${getNoun(true)} ID`;
    }
    return errors;
  };

  const handleSubmit = async (
    values,
    { setStatus, setErrors, setSubmitting }
  ) => {
    try {
      await handleJoin(values.meetingId);
    } catch (error) {
      Bugsnag.notify(error);

      if (error.graphQLErrors[0].message.startsWith(`No such ${getNoun()}`)) {
        setErrors({ meetingId: `There is no ${getNoun()} with that ID` });
        // setStatus("There is no quiz with that ID");
      } else {
        setStatus("Unknown error, try again");
      }
      setSubmitting(false);
    }
  };

  const renderLoadingScreen = () => (
    <>
      {!error && (
        <DarkPageHeading>
          Joining {getNoun(true)} {quizId || eventId}
        </DarkPageHeading>
      )}
      {error && <DarkPageHeading>{error}</DarkPageHeading>}
    </>
  );

  const renderFormScreen = () => (
    <>
      <DarkPageHeading>Join {getNoun(true)}</DarkPageHeading>
      <Formik
        initialValues={{ meetingId: "" }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField>
              <Label>Enter {getNoun(true)} ID</Label>
              <Input
                name="meetingId"
                type="text"
                value={values.meetingId}
                autoFocus
                autoComplete="off"
                autoCapitalize="none"
                autoCorrect="off"
                onChange={handleChange}
              />
              {errors.meetingId && <FormError>{errors.meetingId}</FormError>}
              {status && <FormError>{status}</FormError>}
            </FormField>
            <ButtonRow>
              <PrimaryButton
                type="submit"
                size="large"
                disabled={isSubmitting || joining}
                data-cy="join-button"
              >
                {isSubmitting || joining ? "Joining" : "Join"}
              </PrimaryButton>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <Dialog>
      {(quizId || eventId) && renderLoadingScreen()}
      {!quizId && !eventId && renderFormScreen()}
    </Dialog>
  );
};

export default Join;
