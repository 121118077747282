import React from "react";
import JoinScreen, {
  INPUT_ID,
  INVALID_ID,
  JOINING,
  UNKNOWN_ERROR,
} from "../../../screens/join/JoinScreen";
import sleep from "../../../../util/sleep";
import Bugsnag from "@bugsnag/js";
import { get } from "lodash";
import { useEffect, useState } from "react";
import useJoinQuizMutation from "../../../hooks/mutations/useJoinQuizMutation";

const JoinController = ({ publicIdFromUrl, onJoin }) => {
  const [state, setState] = useState(INPUT_ID);
  const [publicId, setPublicId] = useState("");
  const [joinQuizMutation] = useJoinQuizMutation();

  const handleJoin = async (publicQuizId) => {
    setState(JOINING);
    await sleep(1000);
    try {
      await joinQuizMutation({
        variables: {
          publicQuizId,
        },
      });
      onJoin();
    } catch (error) {
      Bugsnag.notify(error);

      const message = get(error, "graphQLErrors.0.message");
      if (message) {
        if (message.startsWith("No such quiz")) {
          setState(INVALID_ID);
        } else {
          setState(UNKNOWN_ERROR);
        }
      } else {
        setState(UNKNOWN_ERROR);
      }
    }
  };

  useEffect(() => {
    if (publicIdFromUrl) {
      setPublicId(publicIdFromUrl);
      handleJoin(publicIdFromUrl);
    }
  }, [publicIdFromUrl]);

  const handleTryAgain = async () => {
    setPublicId("");
    setState(INPUT_ID);
  };

  return (
    <JoinScreen
      state={state}
      publicId={publicId}
      onPublicIdChange={setPublicId}
      onJoin={handleJoin}
      onTryAgain={handleTryAgain}
    />
  );
};

export default JoinController;
