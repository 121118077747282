import React, { useState } from "react";
import Dialog from "../Dialog";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Label from "../Label";
import Input from "../Input";
import FormError from "../FormError";
import ButtonRow from "../ButtonRow";
import PrimaryButton from "../buttons/PrimaryButton";
import { gql, useMutation } from "@apollo/client";
import { DEBUG } from "../../flags";
import sleep from "../../util/sleep";
import Bugsnag from "@bugsnag/js";
import DarkPageHeading from "../DarkPageHeading";
import { EVENT } from "./index";

const SIGN_IN = gql`
  mutation SignIn($name: String!) {
    signIn(name: $name) {
      ok
    }
  }
`;

const SIGN_IN_EVENT = gql`
  mutation SignInEvent($name: String!) {
    signInEvent(name: $name) {
      ok
    }
  }
`;

const SignIn = ({ mode, onSuccess }) => {
  const [signingIn, setSigningIn] = useState(false);

  const [signIn] = useMutation(SIGN_IN);
  const [signInEvent] = useMutation(SIGN_IN_EVENT);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "You must enter a name";
    }
    return errors;
  };

  const handleSignIn = async (values, { setStatus, setSubmitting }) => {
    try {
      if (mode === EVENT) {
        await signInEvent({
          variables: { name: values.name },
        });
      } else {
        await signIn({
          variables: { name: values.name },
        });
      }
      if (DEBUG) {
        await sleep(1000);
      }
      onSuccess();
      setSigningIn(true);
    } catch (error) {
      Bugsnag.notify(error);

      if (
        error.graphQLErrors[0].message.startsWith(
          "Maximum participants reached"
        )
      ) {
        setStatus("Maximum number of players reached");
      } else {
        setStatus("Unknown error, try again");
      }
      setSubmitting(false);
    }
  };

  return (
    <Dialog>
      <DarkPageHeading>Sign In</DarkPageHeading>
      <Formik
        initialValues={{ name: "" }}
        validate={validate}
        onSubmit={handleSignIn}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField>
              <Label>Enter Screen Name</Label>
              <Input
                name="name"
                type="text"
                value={values.name}
                autoFocus
                autoComplete="off"
                onChange={handleChange}
              />
              {errors.name && <FormError>{errors.name}</FormError>}
              {status && <FormError>{status}</FormError>}
            </FormField>
            <ButtonRow>
              <PrimaryButton
                type="submit"
                size="large"
                disabled={isSubmitting || signingIn}
                data-cy="sign-in-button"
              >
                {isSubmitting || signingIn ? "Signing In" : "Sign In"}
              </PrimaryButton>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SignIn;
